/** @format */

.product_bg {
  background: no-repeat bottom center url("../Helper/images/productbg.svg");
  width: 100%;
  max-height: 540px;
}
.product_container_btn {
  display: flex;
  gap: 28px;
  align-items: center;
}
.product_list_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: calc(100vh - 60px);
  max-height: 690px;
  align-items: center;
}
.productlisttopright,
.product_listtopleft {
  width: 46%;
  padding-bottom: 35px;
}
.productpage_container {
  padding: 0 0 77px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.product_header {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.5px;
}
.product_subheader {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
}
.slider_bg {
  background: url("../Helper/images/monitor_bg.png") no-repeat;
  width: 469px;
  height: 357px;
  position: relative;
}
.sliderinnner {
  position: absolute;
  top: 5px;
  left: 6px;
  width: 100%;
  height: 252px;
}
.slider_bg .slick-list {
  height: 260px;
}
.pro_countcontainer {
  padding-top: 60px;
  padding-bottom: 30px;
}
.pro_countinner {
  display: flex;
}
.procount_list {
  padding: 0 50px;
  max-width: 33.33%;
  text-align: center;
  position: relative;
}
.pro_heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
  color: #226db4;
}
.pro_content {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: rgba(18, 19, 48, 1);
}
.procount_list:before {
  border: 2px solid #226db4;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.pro_countinner .procount_list:first-child:before {
  display: none;
}
.bg_getstarted {
  background: var(--primary-Color, #226db4);
  padding: 60px 0;
}
.pro_getstartinner {
  display: flex;
  gap: 50px;
  align-items: center;
  padding: 0 30px;
}
.procustomer_testimonials {
  padding-top: 60px;
  padding-bottom: 20px;
}
.procustomer_testimonialinner {
  display: flex;
  gap: 50px;
  align-items: center;
}
.testusertophead {
  font-weight: 800;
  font-size: 24px;
  color: rgba(0, 0, 0, 1);
  line-height: 29px;
}
.testusercontent {
  font-size: 400;
  font-size: 16px;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
}
.getstartlft_cont {
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.02em;
}
.pro_getstartright,
.pro_getstartleft {
  width: 47%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.getstartlft_heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.02em;
  width: 80%;
}
.procustomer_testimoniallft {
  padding-left: 15px;
  position: relative;
}
.play_videoarrow {
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
  background: url("../Helper/images/testimonials_videoplay.svg") no-repeat;
  width: 70px;
  height: 70px;
  background-size: 100%;
  cursor: pointer;
  display: none;
}
.procustomer_testimonialrft {
  padding-right: 15px;
}
.testimonials_top {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.testimonials_userinfo {
  margin-top: 20px;
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.infouser_name {
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  line-height: 21px;
}
.infouser_cont {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 14px;
}
.pro_getstartrightslider {
  padding: 8px;
  background: url("../Helper/images/pro_getstartrightsliderbg.png") no-repeat;
  width: 526px;
  background-size: 100% 100%;
  height: 280px;
}
.sliderinnner1 img {
  width: 100%;
  height: 264px;
  object-fit: cover;
}
.sliderinnner1 {
  width: 100%;
  height: 100%;
}
.pro_getstartrightslider .slick-slider {
  height: 263px;
}
.pro_getstartrightslider .slick-slider .slick-list {
  height: 263px;
}
.getstartlft_cont1 {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  line-height: 22px;
  width: 70%;
}
.pro_healthtimergt img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.procustomer_testimoniallft {
  max-width: 615px;
}
.video_container1 video {
  object-fit: inherit;
  aspect-ratio: inherit;
}

/*Responsive*/
@media only screen and (max-width: 1024px) {
  .pro_getstartrightslider {
    width: 100%;
  }
  .sliderinnner1 img {
    object-fit: fill;
  }
}
@media only screen and (max-width: 820px) {
  .slider_bg {
    display: inline-block;
  }
  .product_bg {
    max-height: inherit;
    background: no-repeat bottom center url("../Helper/images/productbg.svg")
      #226db4;
    padding: 20px 0;
  }
  .productpage_container {
    padding: 0;
  }
  .product_list_container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-height: fit-content;
    height: fit-content;
  }
  .productlisttopright,
  .product_listtopleft {
    width: 100%;
    padding-bottom: 20px;
  }
  .productlisttopright {
    text-align: center;
  }
  .procount_list {
    padding: 0 15px;
  }
  .pro_getstartinner {
    flex-wrap: wrap;
    gap: 20px;
  }
  .pro_getstartright,
  .pro_getstartleft {
    width: 100%;
  }
  .procustomer_testimonialinner {
    flex-wrap: wrap;
  }
  .procustomer_testimoniallft {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
  .pro_getstartinner {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .productlisttopright,
  .product_listtopleft {
    padding-bottom: 20px;
  }
  .procount_list {
    max-width: 100%;
  }
  .pro_countinner {
    flex-direction: column;
    gap: 20px;
  }
  .procount_list:before {
    display: none;
  }
  .procustomer_testimonialinner {
    flex-direction: column;
  }
  .procustomer_testimoniallft {
    padding-left: 0;
  }
  .procustomer_testimoniallft img {
    max-width: 100%;
    height: auto;
  }
  .procustomer_testimonialrft {
    padding-right: 0;
  }
  .procustomer_testimonialrft {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .getstartlft_heading {
    font-size: 34px;
    line-height: 44px;
    width: 100%;
  }
  .pro_majorcardtop img {
    height: auto;
  }
}
@media only screen and (max-width: 568px) {
  .bg_getstarted {
    padding: 20px 0;
  }
  .getstartlft_cont1 {
    width: 100%;
  }
  .pro_getstartinner {
    padding: 0 15px;
  }
  .product_container_btn {
    flex-wrap: wrap;
  }
  .procustomer_testimonialinner {
    gap: 0;
  }
  .getstartlft_cont1 {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .slider_bg {
    width: 100%;
    background-size: 100%;
    height: 300px;
  }
  .slider_bg .slick-list {
    height: 190px;
    width: 99%;
  }
  .productlisttopright  .slider_bg .slick-list {height:247px} 

  .productlisttopright,
  .product_listtopleft {
    padding-bottom: 0;
  }
  .sliderinnner1 img {
    height: 100%;
    object-fit: cover;
  }
  .sliderinnner1 {
    height: 204px;
  }
  .procustomer_testimonialrft {
    padding-top: 20px;
  }
  .procustomer_testimonials {
    padding-top: 20px;
  }
  .pro_getstartinner {
    gap: 20px;
  }
  .pro_getstartrightslider {
    height: 220px;
  }
  .slider_bg .slick-track {
    height: 100%;
  }

  .slider_bg .slick-slide img {
    height: 100%;
    width: 100%;
  }
  .pro_countcontainer {
    padding-top: 20px;
  }
  .industry_left_content > .heading{font-size: 24px;line-height: 34px;}
  .industry_left_content > .btn_container{gap: 10px;flex-wrap: wrap;text-align: center;}
  .manufacturingproductlisttopright .sliderinnner{left:4px;top:4px}
  .corporateproductlisttopright .sliderinnner{left:4px;top:4px}
}
@media only screen and (max-width: 430px) {
.productlisttopright .slider_bg .slick-list{height:218px}
.industry_right_content .sliderinnner{top:3px;height:200px;left:4px}
.industry_right_content .slider_bg .slick-list{height:205px}
.industry_right_content .slider_bg .slick-slide img {
  /* height: calc(96% - 5px); */
  width: 100%;
}
}
@media only screen and (max-width: 414px) {
  .slider_bg .slick-list {
    height: 210px;
  }
  .pro_getstartrightslider {
    height: 180px;
  }
  .sliderinnner1 {
    height: 100%;
  }
  .productlisttopright .slider_bg .slick-list{height:209px}
  .productlisttopright .sliderinnner{height:100%}
  .industry_right_content .slider_bg .slick-slide img{height:calc(100% - 9px)}
}
@media only screen and (max-width: 390px) {
  .slider_bg .slick-list {
    height: 197px;
  }
  .productlisttopright .slider_bg .slick-list{height:195px}
  .industry_right_content .slider_bg .slick-slide img {
    height: calc(91% - 5px);
    
  }
}

@media only screen and (max-width: 380px) {
  .slider_bg .slick-list {
    height: 191px;
  }
  .slider_bg{height:260px}
  .productlisttopright .slider_bg .slick-list{height:187px}
  .industry_right_content .sliderinnner{height:191px}
}
@media only screen and (max-width: 360px) {
  .productlisttopright .slider_bg .slick-list{height:184px}
  .industry_right_content .slider_bg .slick-list{height:168px}
}
