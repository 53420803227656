/** @format */

.featurelist_container_left {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  padding: 40px 0;
}
.featurelist_container_left-first-child {
  padding-bottom: 100px;
}
.featurelist_leftside_left {
  position: relative;
  width: 55%;
}
.featurelist_leftside_right {
  position: relative;
  width: 40%;
}
.featurelist_img {
  width: 502px;
  height: 277px;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 4/2.2;
}
.featurelist_leftside_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.featurelist_heading {
  color: var(--primary-Color, #226DB4);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.featurelist_subheading {
  color: #101828;
  font-size: 26px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.52px;
}
.featurelist_content {
  color: #121330;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  margin-top: 20px;
}
.featurelist_bg_gray {
  background-color: #fafafa;
}
.left_side {
  align-items: end;
}
.left_side > .featurelist_content {
  text-align: end;
}
.img_move_top {
  position: absolute;
  top: -70px;
}
.faq-pt {
  padding-top: 50px;
}
.featurelistPage_container > .featurelistpage {
  margin-top: 60px;
}
.featurelist_mobile {
  display: none;
}

.pro_topretailcontainer {
  padding-top: 60px;
  padding-bottom: 60px;
}
.pro_topretailcontent {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: var(--text-secondary-Color, #121330);
  margin-top: 20px;
}
.pro_topretailinner {
  width: 100%;
  text-align: center;
}
.retailcategory_container .category_card_container {
  justify-content: flex-start;
}
.retailcategory_container .feature_subheading {
  max-width: 100%;
  margin: 0 auto;
}
.product_majarinnertop {
  width: 100%;
  text-align: center;
}
.product_majarinnertop .pro_topretailcontent {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
.product_majaorcontainer {
  padding-top: 60px;
  padding-bottom: 20px;
}
.majorprotop_content {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: var(--text-secondary-Color, #121330);
  margin-top: 15px;
  padding-left: 5%;
  padding-right: 5%;
}
.product_majarinnermiddle {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.pro_majorlistcard {
  display: flex;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 8px;
  max-width: 48%;
  flex-direction: column;
}
.pro_majorcardbottom {
  padding: 20px;
}
.pro_majorcardtop img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.card_bottomheading {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(0, 0, 0, 1);
}
.card_bottomcontent {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: var(--text-secondary-Color, #121330);
  margin-top: 20px;
}
.pro_mediamiddleouter .media_card {
  aspect-ratio: inherit;
  padding-bottom: 35px;
}
.pro_mediaoutercontainer .majorprotop_content {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.media_container.pro_mediamiddleouter {
  margin-top: 20px;
  padding-bottom: 50px;
}
.feature_subcontents {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: var(--text-secondary-Color, #121330);
  margin-top: 15px;
  padding: 0 15%;
  text-align: center;
}
.getstartlft_cont1 {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  line-height: 22px;
  width: 70%;
}
.featurelist_product_bg .featurelist_container_left {
  align-items: center;
  padding-bottom: 40px;
}
.featurelist_bg_white.manufacturelist_product_bg .featurelist_container_left {
  padding-bottom: 60px;
  padding-top: 60px;
}
.featurelist_product_bg .featurelist_leftside_left {
  gap: 0;
}
.product_listouter {
  padding-top: 40px;
  padding-bottom: 40px;
}
.product_comsolutioninnermiddle {
  padding: 40px 0;
  display: flex;
}
.container_comsolutionchallenges {
  padding: 40px;
}
.chall_lft {
  background: rgba(247, 236, 255, 1);
  padding: 30px 25px;
  max-width: 50%;
  text-align: left;
  margin: 50px 0 60px 0;
}
.chall_lft .feature_subheading {
  text-align: left;
}
.chall_listing {
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin: 20px 0;
}
.chall_listing li {
  list-style: none;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: rgba(18, 19, 48, 1);
  line-height: 180%;
  padding-left: 20px;
}
.chall_listing li:before {
  content: "";
  left: 0;
  top: 13px;
  background: rgba(18, 19, 48, 1);
  border-radius: 100%;
  width: 5px;
  height: 5px;
  position: absolute;
}
.pr_solright {
  background: #226DB4;
  padding: 80px 25px;
}
.pr_solright .feature_subheading {
  text-align: left;
  color: #fff;
}
.pr_solright .feature_subcontent {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #fff;
}
.pr_solright .chall_listing li {
  color: #fff;
}
.pr_solright .chall_listing li:before {
  background: #fff;
}
.retailcategory_container .category_card_container > .category_card {
  justify-content: inherit;
}
.pro_healthtimecontainer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pro_healthtimecontainerinner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 40px 0px;
  gap: 40px;
}
.pro_healthtimelft,
.pro_healthtimergt {
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.pro_healthtimetopcont {
  font-weight: 400;
  color: rgba(18, 19, 48, 1);
  font-size: 16px;
  line-height: 180%;
}
.pro_healthtimelftlist {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.pro_healthtimelftlist li {
  padding-left: 45px;
  position: relative;
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  color: rgba(18, 19, 48, 1);
  line-height: 180%;
}
.pro_healthtimelftlist li:before {
  background: url("../../Helper/images/product_tick.svg") no-repeat;
  width: 30px;
  height: 30px;
  content: "";
  left: 0;
  top: 5px;
  position: absolute;
}
.pro_corporationtimecontainer .pro_healthtimelftlist li:before {
  top: 0;
}
.manufactlist_subtopheading {
  font-weight: 600;
  color: #226DB4;
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 15px;
}
.manufacturelist_product_bg .featurelist_leftside_left {
  gap: 0;
}
.manufacturelist_product_bg .featurelist_leftside_left {
  justify-content: center;
}
.pro_manufactlistcard .pro_majorcardbottom ul {
  margin-top: 20px;
  list-style: none;
}
.pro_manufactlistcard .pro_majorcardbottom ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  color: var(--text-secondary-Color, #121330);
  position: relative;
  padding-left: 20px;
}
.pro_manufactlistcard .pro_majorcardbottom ul li:before {
  content: "";
  left: 2px;
  top: 12px;
  background: #121330;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  position: absolute;
}
.list_manheading {
  font-weight: 500;
  font-size: 17px;
  color: #121330;
  margin-bottom: 5px;
}
.listwithhead {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-shrink: 0;
}
.com_gridbxmanu {
  max-width: 32%;
}
.com_gridbxmanuinner {
  padding: 15px;
  border: 1px solid #226DB4;
  border-radius: 8px;
  height: 100%;
}
.bg_box {
  background: #226DB4;
  height: 100%;
}
.bg_box * {
  color: #fff;
}
.gridbx_heading {
  font-weight: 600;
  font-size: 17px;
}
.com_gridbxmanuinnerins {
  padding-left: 45px;
  position: relative;
}
.com_gridbxmanuinnerins:before {
  background: url("../../Helper/images/product_tickwhite.svg") no-repeat;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
}
.com_gridbxmanuinnerins:before {
  background: url("../../Helper/images/product_tick.svg") no-repeat;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  content: "";
}
.bg_box .com_gridbxmanuinnerins:before {
  background: url("../../Helper/images/product_tickwhite.svg") no-repeat;
}
.com_gridbxmanu .feature_subheading {
  text-align: left;
}
.gridbx_content {
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  line-height: 170%;
}
.manugactsixboxcontainer {
  padding-bottom: 80px;
  padding-top: 20px;
}
.com_gridbxmanufirst {
  align-items: center;
  display: flex;
}
.pro_majorcardtop img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 1260px) {
  .featurelist_img {
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .com_gridbxmanu {
    max-width: 45%;
  }
  .listwithhead {
    justify-content: center;
  }
}
@media only screen and (max-width: 820px) {
  .product_list_container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-height: fit-content;
    height: fit-content;
  }
  .productpage_container {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .product_list_container {
    flex-direction: column-reverse;
    max-height: fit-content;
    height: fit-content;
  }

  .procustomer_testimonialinner {
    flex-direction: column;
  }
  .retailcategory_container .feature_subheading {
    max-width: 100%;
  }
  .pro_majorlistcard {
    max-width: 100%;
  }
  .pro_mediaoutercontainer .majorprotop_content {
    width: 100%;
  }
  .product_majaorcontainer {
    padding-top: 30px;
  }
  .pro_healthtimelft,
  .pro_healthtimergt {
    width: 100%;
  }
  .pro_healthtimecontainerinner {
    flex-wrap: wrap;
    gap: 0;
  }
  .com_gridbxmanu {
    max-width: 50%;
    width: 50%;
  }
  .com_gridbxmanufirst {
    max-width: 50%;
  }
  .featurelist_bg_gray.featurelist_product_bg .featurelist_container_left {
    flex-direction: column-reverse;
  }
  .pro_healthtimecontainerinner {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 0;
  }
  .product_majarinnertop .pro_topretailcontent {
    width: 100%;
  }
}

@media only screen and (max-width: 723px) {
  .productpage_container {
    padding: 50px 0;
  }
  .featurelist_desktop {
    display: none;
  }
  .featurelist_mobile {
    display: flex;
  }
  .featurelist_container_left {
    flex-direction: column;
  }
  .featurelist_img {
    width: 100%;
    height: auto;
  }
  .featurelist_leftside_left,
  .featurelist_leftside_right {
    width: 100%;
    align-items: center;
  }
  .left_side > .featurelist_content,
  .featurelist_content {
    text-align: center;
  }
  .left_side {
    align-items: center;
  }
  .img_move_top {
    position: relative;
    top: 0px;
  }
  .featurelist_container_left {
    padding-bottom: 40px;
  }
  .feature_bg {
    max-height: fit-content;
  }
}
@media only screen and (max-width: 568px) {
  .feature_header,
  .featurelist_subheading {
    font-size: 25px;
    line-height: 34px;
  }
  .productpage_container {
    padding: 24px 0;
  }
  .featurelistPage_container > .featurelistpage {
    margin-top: 24px;
  }
  .featurelist_leftside_left {
    gap: 0px;
  }
  .featurelist_container_left {
    padding-bottom: 24px;
    padding-top: 30px;
  }
  .faq-pt {
    padding-top: 20px;
  }
  .manugactsixboxcontainer {
    padding-bottom: 40px;
  }
  .bg_getstarted {
    padding: 20px 0;
  }
  .product_container_btn {
    flex-wrap: wrap;
  }
  .procustomer_testimonialinner {
    gap: 0;
  }
  .pro_healthtimecontainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .featurelist_bg_gray .featurelist_container_left {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 480px) {
  .productlisttopright,
  .product_listtopleft {
    padding-bottom: 0;
  }
  .productpage_container {
    padding-top: 0;
  }
  .product_listouter {
    padding-bottom: 40px;
  }
  .featurelist_bg_white.manufacturelist_product_bg .featurelist_container_left {
    padding: 20px 0;
  }
  .com_gridbxmanu {
    max-width: 100%;
    width: 100%;
  }
  .com_gridbxmanufirst {
    max-width: 100%;
  }
  .getstartlft_cont1 {
    width: 100%;
  }
  .product_container_btn {
    justify-content: center;
    gap: 10px;
  }
}
@media only screen and (max-width: 458px) {
  .feature_bg {
    background-position: top;
  }
}
